// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getStorage } from "firebase/storage";

import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyB4HcYhA-PpLpkUH2_uuu8RikZmnBuUvr0",
  authDomain: "editable-pages---showcase.firebaseapp.com",
  projectId: "editable-pages---showcase",
  storageBucket: "editable-pages---showcase.appspot.com",
  messagingSenderId: "612939016765",
  appId: "1:612939016765:web:867a5660717208af729854",
  measurementId: "G-HSWFJ11VFZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);
// const perf = getPerformance(app);

export const auth = getAuth(app);
export const firestore = getFirestore(app);

export default app;
export const providerGoogle = new GoogleAuthProvider();
