import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import {
  CookiePolicyPageIdentifier,
  HomePageIdentifier,
  LayoutSectionAuxOptions,
  LayoutSectionTypes,
  PageType,
} from "../../../firebase/types";
import Aux from "../../../hoc/auxiliary";
import SimpleLoader from "../../../components/animations/simple-loader/SimpleLoader";
import LayoutComponent from "../../../components/admin-pages/LayoutComponent";
import { findDefaultLayoutSectionPerType } from "../../../helper-functions/general";

function DynamicPage({
  homePage,
  outletPath,
  use_default_layout_section,
}: {
  homePage?: boolean;
  outletPath?: string;
  use_default_layout_section?: boolean;
}) {
  const { id } = useParams();
  const { get_page_by_path, layoutSections } = useAppContext();
  const [page, setPage] = useState<PageType>();

  useEffect(() => {
    const getPageDetails = async () => {
      let pagePath = "";
      if (homePage) {
        pagePath = HomePageIdentifier;
      } else if (id && id === CookiePolicyPageIdentifier) {
        pagePath = CookiePolicyPageIdentifier;
      } else if (id) {
        pagePath = id;
      }

      if (outletPath) pagePath = outletPath;

      const pageDb = await get_page_by_path(pagePath);
      if (pageDb) {
        setPage(pageDb);
      }
    };

    if (id || homePage) getPageDetails();
  }, [id, homePage, outletPath, get_page_by_path]);

  const test_show_layout_section = (defVal: any, pageVal: any) => {
    if (pageVal === LayoutSectionAuxOptions.DONT_USE) {
      return false;
    }
    if (!defVal && pageVal === LayoutSectionAuxOptions.USE_DEFAULT) {
      return false;
    }
    if (!defVal && !pageVal) return false;
    return true;
  };

  const defSettingHeader = useMemo(
    () =>
      findDefaultLayoutSectionPerType(
        layoutSections,
        LayoutSectionTypes.HEADER
      ),
    [layoutSections]
  );
  const defSettingCL = useMemo(
    () =>
      findDefaultLayoutSectionPerType(
        layoutSections,
        LayoutSectionTypes.CONTENT_LEFT
      ),
    [layoutSections]
  );
  const defSettingCR = useMemo(
    () =>
      findDefaultLayoutSectionPerType(
        layoutSections,
        LayoutSectionTypes.CONTENT_RIGHT
      ),
    [layoutSections]
  );
  const defSettingFooter = useMemo(
    () =>
      findDefaultLayoutSectionPerType(
        layoutSections,
        LayoutSectionTypes.FOOTER
      ),
    [layoutSections]
  );

  const pageSettingHeader = page?.layoutStructure?.header;
  const pageSettingCL = page?.layoutStructure?.content_left;
  const pageSettingCR = page?.layoutStructure?.content_right;
  const pageSettingFooter = page?.layoutStructure?.footer;

  if (!page)
    return (
      <div className="ClientLoader">
        <SimpleLoader />
      </div>
    );

  return (
    <Aux>
      {test_show_layout_section(defSettingHeader, pageSettingHeader) && (
        <LayoutComponent
          page={page}
          type={LayoutSectionTypes.HEADER}
          use_default_layout_section={use_default_layout_section}
        />
      )}

      <LayoutComponent
        page={page}
        type={LayoutSectionTypes.MIDDLE}
        use_default_layout_section={use_default_layout_section}
      >
        {test_show_layout_section(defSettingCL, pageSettingCL) && (
          <LayoutComponent
            page={page}
            type={LayoutSectionTypes.CONTENT_LEFT}
            use_default_layout_section={use_default_layout_section}
          />
        )}

        <div style={{ width: "100%" }}>
          <LayoutComponent
            page={page}
            type={LayoutSectionTypes.ROUTES}
            use_default_layout_section={use_default_layout_section}
          />
        </div>

        {test_show_layout_section(defSettingCR, pageSettingCR) && (
          <LayoutComponent
            page={page}
            type={LayoutSectionTypes.CONTENT_RIGHT}
            use_default_layout_section={use_default_layout_section}
          />
        )}
      </LayoutComponent>

      {test_show_layout_section(defSettingFooter, pageSettingFooter) && (
        <LayoutComponent
          page={page}
          type={LayoutSectionTypes.FOOTER}
          use_default_layout_section={use_default_layout_section}
        />
      )}
    </Aux>
  );
}

export default DynamicPage;
