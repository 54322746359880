import classes from "./layout-mockup.module.css";
import {
  LayoutSectionAuxOptions,
  LayoutSectionTypes,
  LayoutStructureType,
} from "../../firebase/types";
import SelectLayoutSection from "../admin-pages/SelectLayoutSection";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import { useCallback, useMemo } from "react";
import { getDefaultLayoutSectionID } from "../../helper-functions/general";

export interface LayoutMockupSelectType {
  opt: { label: string; value: string };
  type: LayoutSectionTypes;
}

function LayoutMockup({
  handleChange,
  forRoutes,
  layoutStructure,
}: {
  handleChange?: any;
  forRoutes?: boolean;
  layoutStructure?: LayoutStructureType;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    update_layout_section,
    set_layout_sections_is_default_to_false_by_type,
    layoutSections,
  } = useAppContext();

  // AICI
  // const styleOuter = useMemo(() => {
  //   return settings && settings.layoutStyles
  //     ? parseJsonStringifiedToJsonObject(
  //         isMobileDevice
  //           ? settings.layoutStyles.styleOuterM
  //           : settings.layoutStyles.styleOuter
  //       )
  //     : {};
  // }, [settings, isMobileDevice]);

  const defaultHID = useMemo(
    () =>
      getDefaultLayoutSectionID(
        layoutSections.filter((el) => el.type === LayoutSectionTypes.HEADER)
      ),
    [layoutSections]
  );
  const customHID = useMemo(
    () =>
      layoutStructure && layoutStructure.header
        ? layoutStructure.header
        : undefined,
    [layoutStructure]
  );

  const defaultMID = useMemo(
    () =>
      getDefaultLayoutSectionID(
        layoutSections.filter((el) => el.type === LayoutSectionTypes.MIDDLE)
      ),
    [layoutSections]
  );
  const customMID = useMemo(
    () =>
      layoutStructure && layoutStructure.content_container
        ? layoutStructure.content_container
        : undefined,
    [layoutStructure]
  );

  const defaultCLID = useMemo(
    () =>
      getDefaultLayoutSectionID(
        layoutSections.filter(
          (el) => el.type === LayoutSectionTypes.CONTENT_LEFT
        )
      ),
    [layoutSections]
  );
  const customCLID = useMemo(
    () =>
      layoutStructure && layoutStructure.content_left
        ? layoutStructure.content_left
        : undefined,
    [layoutStructure]
  );

  const defaultCRID = useMemo(
    () =>
      getDefaultLayoutSectionID(
        layoutSections.filter(
          (el) => el.type === LayoutSectionTypes.CONTENT_RIGHT
        )
      ),
    [layoutSections]
  );
  const customCRID = useMemo(
    () =>
      layoutStructure && layoutStructure.content_right
        ? layoutStructure.content_right
        : undefined,
    [layoutStructure]
  );

  const defaultFID = useMemo(
    () =>
      getDefaultLayoutSectionID(
        layoutSections.filter((el) => el.type === LayoutSectionTypes.FOOTER)
      ),
    [layoutSections]
  );
  const customFID = useMemo(
    () =>
      layoutStructure && layoutStructure.footer
        ? layoutStructure.footer
        : undefined,
    [layoutStructure]
  );

  const handleChangeSelect = useCallback(
    (opt: { label: string; value: string }, type: LayoutSectionTypes) => {
      const handle_change_in_admin_layouts = async (
        opt: any,
        type: LayoutSectionTypes
      ) => {
        if (opt.value === LayoutSectionAuxOptions.DONT_USE) {
          await set_layout_sections_is_default_to_false_by_type(type);
          return;
        }

        if (!layoutSections) return;
        const lS = layoutSections.find((el) => el.id === opt.value);
        if (lS) await update_layout_section({ ...lS, isDefault: true });
      };

      if (handleChange) {
        // in routes:
        handleChange(opt, type);
      } else handle_change_in_admin_layouts(opt, type);
    },
    [
      layoutSections,
      update_layout_section,
      set_layout_sections_is_default_to_false_by_type,
      handleChange,
    ]
  );

  const openHeader = () => {
    navigate("/admin/layout/" + LayoutSectionTypes.HEADER);
  };
  const openContentContainer = () => {
    navigate("/admin/layout/" + LayoutSectionTypes.MIDDLE);
  };
  const openLeft = () => {
    navigate("/admin/layout/" + LayoutSectionTypes.CONTENT_LEFT);
  };
  const openRoutes = () => {
    navigate("/admin/routes");
  };
  const openRight = () => {
    navigate("/admin/layout/" + LayoutSectionTypes.CONTENT_RIGHT);
  };
  const openFooter = () => {
    navigate("/admin/layout/" + LayoutSectionTypes.FOOTER);
  };

  if (!layoutSections) return <div></div>;

  return (
    <div className={classes.Outer}>
      <div className={classes.Inner}>
        <div className={classes.HeaderInner}>
          <p className={classes.Label}>
            <span onClick={openHeader}>{t("header")}</span>
          </p>
          <SelectLayoutSection
            type={LayoutSectionTypes.HEADER}
            handleChange={handleChangeSelect}
            initialID={forRoutes ? customHID : defaultHID}
            forRoutes={forRoutes}
          />
        </div>

        <div className={classes.Middle}>
          <p className={classes.Label}>
            <span onClick={openContentContainer}>{t("content_container")}</span>
          </p>
          <SelectLayoutSection
            type={LayoutSectionTypes.MIDDLE}
            handleChange={handleChangeSelect}
            initialID={forRoutes ? customMID : defaultMID}
            forRoutes={forRoutes}
          />
          <br />

          <div
            className={classes.ContentInner} //style={styleOuter}
          >
            <div className={classes.Column}>
              <p className={classes.Label}>
                <span onClick={openLeft}>{t("content_left")}</span>
              </p>
              <SelectLayoutSection
                type={LayoutSectionTypes.CONTENT_LEFT}
                handleChange={handleChangeSelect}
                initialID={forRoutes ? customCLID : defaultCLID}
                forRoutes={forRoutes}
              />
            </div>

            <div className={classes.Column}>
              <div className={classes.LabelRoutes}>
                {forRoutes ? (
                  t("route_main_details")
                ) : (
                  <p
                    className={classes.Label}
                    style={{ paddingTop: "8px" }}
                    onClick={openRoutes}
                  >
                    <span>{t("routes")}</span>
                  </p>
                )}
              </div>
            </div>

            <div className={classes.Column}>
              <p className={classes.Label}>
                <span onClick={openRight}>{t("content_right")}</span>
              </p>
              <SelectLayoutSection
                type={LayoutSectionTypes.CONTENT_RIGHT}
                handleChange={handleChangeSelect}
                initialID={forRoutes ? customCRID : defaultCRID}
                forRoutes={forRoutes}
              />
            </div>
          </div>
        </div>
        <div className={classes.HeaderInner}>
          <p className={classes.Label}>
            <span onClick={openFooter}>{t("footer")}</span>
          </p>
          <SelectLayoutSection
            type={LayoutSectionTypes.FOOTER}
            handleChange={handleChangeSelect}
            initialID={forRoutes ? customFID : defaultFID}
            forRoutes={forRoutes}
          />
        </div>
      </div>
    </div>
  );
}

export default LayoutMockup;
