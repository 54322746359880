import {
  HomePageIdentifier,
  CookiePolicyPageIdentifier,
  PageDataType,
  PageType,
  BasicComponentType,
  BasicComponentDataType,
  BasicComponentTypes,
  WrapperComponentType,
  LayoutSectionType,
  LayoutSectionTypes,
  AdminPaths,
  LayoutStructureType,
  LayoutSectionAuxOptions,
} from "../firebase/types";

// not used yet - remove this comment when implementing:
export const getPageDataByLanguage = (page: PageType, lang: string) => {
  if (!page || !lang) return null;
  return page.data.find((d: any) => d.language === lang);
};

export const parseImageBytes = (bytes?: number) => {
  if (!bytes || !+bytes) return "-";

  const unitSize = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(unitSize));
  const sizeFloat = parseFloat((bytes / Math.pow(unitSize, i)).toFixed(2));

  let clsName = "ColorCodeGood";
  if (i === 2 && sizeFloat < 7) {
    clsName = "ColorCodeOk";
  }
  if (i > 1 && sizeFloat >= 7) {
    clsName = "ColorCodeBad";
  }

  return (
    <p className={clsName}>
      {sizeFloat} {sizes[i]}
    </p>
  );
};

export const flagIncompletePageTranslations = (
  page: PageType,
  t: any
): string => {
  let problem = "";
  page.data.forEach((dt: PageDataType) => {
    if (!dt.content || !dt.linkLabel) {
      problem += (problem !== "" ? ", " : "") + t(dt.language);
    }
  });
  return problem !== "" ? t("missing_details_for") + problem : "";
};

export const getCardsSelectOptions = (
  result: any[],
  bc: BasicComponentType[]
) => {
  // HERE
  const parsed = bc.map((el: any) => ({
    value: el.id,
    label: el.name,
    id: el.id,
  }));
  result.push(...parsed);
  return result;
};
export const generateCardDataOptions = (result: any[], data: any[]) => {
  let ind = -1;
  const parsed = data.map((el: any) => {
    ind++;
    return {
      value: el.id,
      label: getCardDataOptionLabel(el, ind),
      id: el.id,
    };
  });
  result.push(...parsed);
  return result;
};
const getCardDataOptionLabel = (el: any, ind: number) => {
  let result = ind;
  if (el.text) {
    if (el.text.ro) result = el.text.ro;
    if (el.text.en) result = el.text.en;
  }
  if (el.title) {
    if (el.title.ro) result = el.title.ro;
    if (el.title.en) result = el.title.en;
  }

  return result;
};

export const getSlideShowsSelectOptions = (
  result: any[],
  arr: BasicComponentType[]
) => {
  const parsed = arr.map((el: any) => ({
    value: el.id,
    label: el.name,
    id: el.id,
  }));
  result.push(...parsed);
  return result;
};
export const generateSlideShowDataOptions = (result: any[], data: any[]) => {
  let ind = -1;
  const parsed = data.map((el: any) => {
    ind++;
    return {
      value: el.id,
      label: getCardDataOptionLabel(el, ind),
      id: el.id,
    };
  });
  result.push(...parsed);
  return result;
};

export const sortAscendingById = (list: any) => {
  return list ? list.sort((a: any, b: any) => a.id - b.id) : list;
};
export const sortStringAscendingByKey = (key: string) => {
  return (a: any, b: any) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

    var result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    return result;
  };
};
export const sortStringDescendingByKey = (key: string) => {
  return (a: any, b: any) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

    var result = a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0;
    return result;
  };
};

export const convertTextareaJsxToObject = (data: string, t: any) => {
  let parsedObj: any = {};
  let hasUndefined = false;
  try {
    data &&
      data.split("\n").forEach((pair: string) => {
        if (pair === "") return;

        let kp: any = pair.split(":");
        if (kp[1] && kp[1].includes("http")) {
          kp[1] = kp[1] + ":" + kp[2];
          kp.pop();
        }
        if (!kp[0] || kp[0] === undefined || !kp[1] || kp[1] === undefined) {
          hasUndefined = true;
        }
        if (kp[0] && kp[1])
          parsedObj = { ...parsedObj, [kp[0].trim()]: kp[1].trim() };
      });
    if (hasUndefined) throw new Error(t("check_value_pairs_error"));
    JSON.stringify(parsedObj);
  } catch (error) {
    parsedObj = { err: error };
  }
  return parsedObj;
};
export const parseJsonStringifiedToTextareaJsx = (val?: string) => {
  let result = "";
  if (!val || val === "") return result;

  let obj: any;
  try {
    obj = JSON.parse(val);
  } catch (error) {
    console.log(
      "Could not be converted to JSON object. Returned empty string. Original string:"
    );
    return "";
  }

  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      result += key + ":" + obj[key] + "\n";
    });
  }

  return result;
};
export const parseJsonStringifiedToJsonObject = (val?: string) => {
  let result: any = {};
  if (!val || val === "") return result;

  try {
    result = JSON.parse(val);
  } catch (error) {
    console.log(
      "Could not be converted to JSON object. Returned empty object. Original string:"
    );
    return {};
  }
  return result;
};

export const parseStyleCard = (
  data: BasicComponentDataType,
  styleListElement: any
) => {
  let resultObj = styleListElement ? { ...styleListElement } : {};
  if (!styleListElement) {
    if (data.backgroundImage) {
      resultObj.backgroundImage = `url(${data.backgroundImage})`;
    }
    if (data.backgroundColor) {
      resultObj.backgroundColor = `url(${data.backgroundColor})`;
    }
  } else {
    if (data.backgroundColor) {
      resultObj = { ...resultObj, backgroundColor: data.backgroundColor };
    }
    if (data.backgroundImage) {
      resultObj = {
        ...resultObj,
        backgroundImage: `url(${data.backgroundImage})`,
      };
    }
  }
  return resultObj;
};
export const parseStylePage = (
  page: BasicComponentDataType,
  styleListElement: any
) => {
  let resultObj = styleListElement ? { ...styleListElement } : {};
  if (!styleListElement) {
    if (page.backgroundImage) {
      resultObj.backgroundImage = `url(${page.backgroundImage})`;
    }
    if (page.backgroundColor) {
      resultObj.backgroundColor = `url(${page.backgroundColor})`;
    }
  } else {
    if (page.backgroundColor) {
      resultObj = { ...resultObj, backgroundColor: page.backgroundColor };
    }
    if (page.backgroundImage) {
      resultObj = {
        ...resultObj,
        backgroundImage: `url(${page.backgroundImage})`,
      };
    }
  }
  return resultObj;
};

export const detectSpecialPagesActive = (pathname: string, to: any) => {
  if (
    pathname === `${AdminPaths.ROUTES}/%3Ccookies-policy%3E` &&
    to === `${AdminPaths.ROUTES}/${CookiePolicyPageIdentifier}`
  ) {
    return true;
  }
  if (
    pathname === `${AdminPaths.ROUTES}/%3Chome-page%3E` &&
    to === `${AdminPaths.ROUTES}/${HomePageIdentifier}`
  ) {
    return true;
  }

  return false;
};

export const getLabelItemVsItems = (nmb: number, t: any) => {
  if (!t) return "";
  return nmb + " " + t(nmb === 1 ? "item" : "items").toLowerCase();
};

export const getSelectOptionsForAllBasicComponents = (
  pool: BasicComponentType[],
  res: any[],
  t: any
) => {
  pool = pool.sort(sortStringAscendingByKey("name"));

  const add_found = (arr: any[], data: any[]) => {
    return [
      ...arr,
      {
        label: t(`${found[0].type}s`),
        isCategoryName: true,
      },
      ...found.map((el: any) => ({
        value: el.id,
        label: el.name,
        type: el.type,
      })),
    ];
  };

  let found = pool.filter(
    (bc: BasicComponentType) => bc.type === BasicComponentTypes.CARD
  );
  if (found) res = add_found(res, found);
  found = pool.filter(
    (bc: BasicComponentType) => bc.type === BasicComponentTypes.DROPDOWN
  );
  if (found) res = add_found(res, found);
  found = pool.filter(
    (bc: BasicComponentType) => bc.type === BasicComponentTypes.FAQ
  );
  if (found) res = add_found(res, found);
  found = pool.filter(
    (bc: BasicComponentType) => bc.type === BasicComponentTypes.SLIDESHOW
  );
  if (found) res = add_found(res, found);
  return res;
};
export const getSelectOptionsForAllHocComponents = (
  pool: WrapperComponentType[],
  res: any[],
  t: any
) => {
  res = [
    ...res,
    {
      label: t("wrapper_components"),
      isCategoryName: true,
    },
    ...pool.sort(sortStringAscendingByKey("name")).map((el: any) => ({
      value: el.id,
      label: el.name,
      type: "hoc",
    })),
  ];

  return res;
};
export const getSelectOptionsForAllComponents = (
  bc: BasicComponentType[],
  wc: WrapperComponentType[],
  t: any
) => {
  let arr: any[] = [];
  if (wc) {
    arr = getSelectOptionsForAllHocComponents(wc, arr, t);
  }
  if (bc) {
    arr = getSelectOptionsForAllBasicComponents(bc, arr, t);
  }
  return arr;
};

export const getDefaultLayoutSectionID = (pool: LayoutSectionType[]) => {
  let result: string = LayoutSectionAuxOptions.DONT_USE;
  if (!pool) return result;
  const def = pool.find((ls: LayoutSectionType) => ls.isDefault);
  if (def && def.id) result = def.id;
  return result;
};
export const getLayoutSectionByID = (pool: LayoutSectionType[], id: string) => {
  if (!pool) return -1;
  const def = pool.find((ls: LayoutSectionType) => ls.id === id);
  return def && def.id ? def.id : -1;
};

export const findDefaultLayoutSectionPerType = (
  layoutSections: LayoutSectionType[],
  type: LayoutSectionTypes
) => {
  if (!layoutSections || !type) return undefined;
  const def = layoutSections
    .filter((ls: LayoutSectionType) => ls.type === type)
    .find((ls: LayoutSectionType) => ls.isDefault);
  return def;
};

export const getComponentById = (
  poolBasic: BasicComponentType[],
  poolHoc: WrapperComponentType[],
  id: string
) => {
  let found: BasicComponentType | WrapperComponentType | undefined =
    poolBasic.find((elem: BasicComponentType) => elem.id === id);

  if (!found) {
    found = poolHoc.find((elem: WrapperComponentType) => elem.id === id);
  }
  return found;
};

export const testPageLayoutSettingsIsDefault = (
  pageLayoutStructure?: LayoutStructureType
) => {
  if (!pageLayoutStructure || !Object.keys(pageLayoutStructure).length) {
    return true;
  }

  let type = LayoutSectionTypes.HEADER;
  if (
    !pageLayoutStructure.hasOwnProperty(type) ||
    (pageLayoutStructure.hasOwnProperty(type) &&
      pageLayoutStructure[type] === LayoutSectionAuxOptions.USE_DEFAULT)
  ) {
  } else return false;

  type = LayoutSectionTypes.CONTENT_LEFT;
  if (
    !pageLayoutStructure.hasOwnProperty(type) ||
    (pageLayoutStructure.hasOwnProperty(type) &&
      pageLayoutStructure[type] === LayoutSectionAuxOptions.USE_DEFAULT)
  ) {
  } else return false;

  type = LayoutSectionTypes.CONTENT_RIGHT;
  if (
    !pageLayoutStructure.hasOwnProperty(type) ||
    (pageLayoutStructure.hasOwnProperty(type) &&
      pageLayoutStructure[type] === LayoutSectionAuxOptions.USE_DEFAULT)
  ) {
  } else return false;

  type = LayoutSectionTypes.FOOTER;
  if (
    !pageLayoutStructure.hasOwnProperty(type) ||
    (pageLayoutStructure.hasOwnProperty(type) &&
      pageLayoutStructure[type] === LayoutSectionAuxOptions.USE_DEFAULT)
  ) {
  } else return false;

  return true;
};

export const ConsoleLog = (label: string, value: any) => {
  if (!window || !window.location || window.location.hostname !== "localhost") {
    return;
  }

  try {
    console.log(`%c${label}: %c${value}`, "color: green", "font-size: 1rem");
  } catch (error) {}
};
