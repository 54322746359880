import { CSSProperties, ReactNode, useMemo } from "react";
import { useAppContext } from "../../context/AppContext";
import { LayoutSectionTypes, LayoutStyleType } from "../../firebase/types";
import { parseJsonStringifiedToJsonObject } from "../../helper-functions/general";
import StyledDivOrAux from "../../hoc/StyledDivOrAux";

interface LayoutWrapperType {
  layoutStyles?: LayoutStyleType;
  type?: string;
  children?: ReactNode;
}

function LayoutWrapper({ layoutStyles, type, children }: LayoutWrapperType) {
  const { isMobileDevice } = useAppContext();

  let styleObj = useMemo(() => {
    let result: CSSProperties = {};
    if (!layoutStyles) return result;

    let forMobile = "",
      forDesktop = "";

    switch (type) {
      case LayoutSectionTypes.HEADER:
        forMobile = layoutStyles?.styleHeaderM ? layoutStyles.styleHeaderM : "";
        forDesktop = layoutStyles?.styleHeader ? layoutStyles.styleHeader : "";
        break;
      case LayoutSectionTypes.MIDDLE:
        forMobile = layoutStyles?.styleMiddleM ? layoutStyles.styleMiddleM : "";
        forDesktop = layoutStyles?.styleMiddle ? layoutStyles.styleMiddle : "";
        break;
      case LayoutSectionTypes.ROUTES:
        forMobile = layoutStyles?.styleColumn2M
          ? layoutStyles.styleColumn2M
          : "";
        forDesktop = layoutStyles?.styleColumn2
          ? layoutStyles.styleColumn2
          : "";
        break;
      case LayoutSectionTypes.CONTENT_LEFT:
        forMobile = layoutStyles?.styleColumn1M
          ? layoutStyles.styleColumn1M
          : "";
        forDesktop = layoutStyles?.styleColumn1
          ? layoutStyles.styleColumn1
          : "";
        break;
      case LayoutSectionTypes.FOOTER:
        forMobile = layoutStyles?.styleFooterM ? layoutStyles.styleFooterM : "";
        forDesktop = layoutStyles?.styleFooter ? layoutStyles.styleFooter : "";
        break;
      case LayoutSectionTypes.CONTENT_RIGHT:
        forMobile = layoutStyles?.styleColumn3M
          ? layoutStyles.styleColumn3M
          : "";
        forDesktop = layoutStyles?.styleColumn3
          ? layoutStyles.styleColumn3
          : "";
        break;

      default:
        break;
    }
    result = parseJsonStringifiedToJsonObject(
      isMobileDevice ? forMobile : forDesktop
    );

    return result;
  }, [layoutStyles, type, isMobileDevice]);

  return <StyledDivOrAux styleObj={styleObj}>{children}</StyledDivOrAux>;
}

export default LayoutWrapper;
