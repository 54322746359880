import classes from "./basic-component.module.css";
import { useAppContext } from "../../context/AppContext";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  parseJsonStringifiedToJsonObject,
  parseStyleCard,
} from "../../helper-functions/general";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  BasicComponentDataType,
  BasicComponentType,
} from "../../firebase/types";
import StyledDivOrAux from "../../hoc/StyledDivOrAux";
import useOnClickOutside from "../../hoc/hooks/useOnClickOutside";
import { useTranslation } from "react-i18next";
import DynamicPage from "../../routes/public/dynamic-pages/Dynamic-pages";

function DropdownComponent({
  dropdown,
}: {
  dropdown: BasicComponentType;
}): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language, change_language, isMobileDevice } = useAppContext();
  const [isHovering, setIsHovering] = useState(-1);
  const [hoverLabel, setHoverLabel] = useState(false);
  const [hoverList, setHoverList] = useState(false);
  const [showList, setShowList] = useState(false);

  const [showIFrame, setShowIFrame] = useState("");
  const [outletPath, setOutletPath] = useState("");

  useEffect(() => {
    setShowIFrame("");
    setOutletPath("");
  }, [dropdown]);

  const shouldHoverLabel = useMemo(
    () =>
      Object.keys(parseJsonStringifiedToJsonObject(dropdown?.styleLabelHover))
        .length > 0,
    [dropdown]
  );
  const toggleHoverLabel = useCallback(
    (val: boolean) => shouldHoverLabel && setHoverLabel(val),
    [shouldHoverLabel]
  );

  const toggleShowList = useCallback(() => {
    setShowList((state) => !state);
  }, []);

  //

  const shouldHoverList = useMemo(
    () =>
      Object.keys(parseJsonStringifiedToJsonObject(dropdown?.styleListHover))
        .length > 0,
    [dropdown]
  );
  const toggleHoverList = useCallback(
    (val: boolean) => shouldHoverList && setHoverList(val),
    [shouldHoverList]
  );

  const cardHoverStyle = useMemo(() => {
    return parseJsonStringifiedToJsonObject(
      isMobileDevice ? "" : dropdown.styleListElementHover
    );
  }, [isMobileDevice, dropdown]);
  const elementStyle = useMemo(() => {
    return parseJsonStringifiedToJsonObject(
      isMobileDevice ? dropdown.styleListElementM : dropdown.styleListElement
    );
  }, [isMobileDevice, dropdown]);

  const shouldHover = useMemo(
    () => Object.keys(cardHoverStyle).length > 0,
    [cardHoverStyle]
  );
  const toggleIsHovering = useCallback(
    (val: number) => shouldHover && setIsHovering(val),
    [shouldHover]
  );

  const handleClick = (
    ev: React.BaseSyntheticEvent,
    path?: string,
    openIn?: number
  ) => {
    ev.stopPropagation();
    if (!path) return;

    switch (openIn) {
      case 1:
        window.open(path, "_blank");
        break;
      case 2:
        if (path.indexOf("http") === 0) {
          setShowIFrame(path);
          setOutletPath("");
        } else {
          setShowIFrame("");
          setOutletPath(path);
        }
        break;

      default:
        if (path.indexOf("https://") === 0) {
          window.open(path, "_self");
        } else {
          navigate(path.indexOf("/") === 0 ? path : `/page/${path}`);
        }
        break;
    }
  };

  const refLabel: any = useRef();
  const refList: any = useRef();
  useOnClickOutside([refLabel], () =>
    setTimeout(() => {
      setShowList(false);
    }, 250)
  );

  if (!dropdown) return <div></div>;

  const renderLanguageLabel = () => {
    if (!dropdown || !dropdown.data || !dropdown.data.length)
      return <p>- {t("add_data")} -</p>;

    let el = dropdown.data.find(
      (dt: BasicComponentDataType) => dt.languageCode === language
    );
    if (!el) el = dropdown.data[0];

    return (
      <div style={parseStyleCard(el, elementStyle)}>
        <div
          className={classes.Title}
          style={parseJsonStringifiedToJsonObject(
            isMobileDevice ? dropdown.styleTitleM : dropdown.styleTitle
          )}
        >
          {el.title && el.title.hasOwnProperty(language)
            ? el.title[language]
            : el.title.en}
        </div>
      </div>
    );
  };

  const handleClickElement = (
    ev: React.BaseSyntheticEvent,
    el: BasicComponentDataType
  ) => {
    if (dropdown.isLanguageSelect) {
      const code: any = el && el.languageCode ? el.languageCode : "en";
      change_language(code);
    } else {
      handleClick(ev, el.itemClick, el.openIn);
    }
  };

  return (
    <StyledDivOrAux
      styleObj={parseJsonStringifiedToJsonObject(
        isMobileDevice ? dropdown.styleOuterM : dropdown.styleOuter
      )}
    >
      <motion.div
        initial={{ opacity: 0.25 }}
        whileInView={{ opacity: 1 }}
        ref={refLabel}
        style={parseJsonStringifiedToJsonObject(
          isMobileDevice
            ? dropdown.styleLabelM
            : hoverLabel
            ? dropdown.styleLabelHover
            : dropdown.styleLabel
        )}
        onMouseEnter={() => toggleHoverLabel(true)}
        onMouseLeave={() => toggleHoverLabel(false)}
        onClick={toggleShowList}
      >
        {dropdown.isLanguageSelect
          ? renderLanguageLabel()
          : dropdown?.label[language]
          ? dropdown?.label[language]
          : ""}
      </motion.div>

      {showList && (
        <div
          ref={refList}
          style={parseJsonStringifiedToJsonObject(
            isMobileDevice
              ? dropdown.styleListM
              : hoverList
              ? dropdown.styleListHover
              : dropdown.styleList
          )}
          onMouseEnter={() => toggleHoverList(true)}
          onMouseLeave={() => toggleHoverList(false)}
        >
          {dropdown.data &&
            dropdown.data.map((el: BasicComponentDataType, idx: number) => (
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                key={idx}
                style={parseStyleCard(
                  el,
                  isHovering === idx ? cardHoverStyle : elementStyle
                )}
                onClick={(ev: React.BaseSyntheticEvent) =>
                  handleClickElement(ev, el)
                }
                onMouseEnter={() => toggleIsHovering(idx)}
                onMouseLeave={() => toggleIsHovering(-1)}
              >
                {el.title &&
                el.title.hasOwnProperty(language) &&
                el.title[language] !== "" ? (
                  <div
                    className={classes.Title}
                    style={parseJsonStringifiedToJsonObject(
                      isMobileDevice
                        ? dropdown.styleTitleM
                        : dropdown.styleTitle
                    )}
                  >
                    {el.title[language]}
                  </div>
                ) : null}

                {el.text &&
                el.text.hasOwnProperty(language) &&
                el.text[language] !== "" ? (
                  <div
                    className={classes.Text}
                    style={parseJsonStringifiedToJsonObject(
                      isMobileDevice ? dropdown.styleTextM : dropdown.styleText
                    )}
                    dangerouslySetInnerHTML={{
                      __html: el.text[language],
                    }}
                  ></div>
                ) : null}
              </motion.div>
            ))}
        </div>
      )}

      {outletPath && (
        <div
          style={parseJsonStringifiedToJsonObject(
            isMobileDevice ? dropdown.styleOutletM : dropdown.styleOutlet
          )}
        >
          <DynamicPage outletPath={outletPath} />
        </div>
      )}

      {showIFrame && (
        <iframe
          title="ifr"
          name="ifr"
          className={showIFrame ? classes.Outlet : classes.OutletHidden}
          style={parseJsonStringifiedToJsonObject(
            isMobileDevice ? dropdown.styleOutletM : dropdown.styleOutlet
          )}
          src={showIFrame}
          width="900"
          height="600"
          frameBorder="0"
        >
          &nbsp;
        </iframe>
      )}
    </StyledDivOrAux>
  );
}

export default DropdownComponent;
