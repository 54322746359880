import classes from "../../routes/admin/admin-classes.module.css";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  LayoutSectionAuxOptions,
  LayoutSectionType,
  LayoutSectionTypes,
} from "../../firebase/types";
import { getDefaultLayoutSectionID } from "../../helper-functions/general";
import Select, {
  SelectOptionTextAlign,
  SelectOptionType,
} from "../ui/select/Select";
import { useAppContext } from "../../context/AppContext";

function SelectLayoutSection({
  type,
  handleChange,
  initialID,
  forRoutes,
}: {
  type: LayoutSectionTypes;
  handleChange: any;
  initialID?: string;
  forRoutes?: boolean;
}) {
  const { t } = useTranslation();
  const { layoutSections } = useAppContext();

  const poolByType = useMemo(
    () => layoutSections.filter((ls: LayoutSectionType) => ls.type === type),
    [layoutSections, type]
  );

  const defaultID = useMemo(
    () => getDefaultLayoutSectionID(poolByType),
    [poolByType]
  );

  const rawOptions = useMemo(() => {
    let opts: SelectOptionType[] = [
      {
        label: `${t(LayoutSectionAuxOptions.DONT_USE)} ${t(type)}`,
        value: LayoutSectionAuxOptions.DONT_USE,
      },
    ];
    if (defaultID === LayoutSectionAuxOptions.DONT_USE) {
      opts[0].description = t("the_default_setting");
    }
    if (forRoutes) {
      opts.push({
        label: t(LayoutSectionAuxOptions.USE_DEFAULT),
        value: LayoutSectionAuxOptions.USE_DEFAULT,
        description: t("use_default_expl_1"),
      });
    }
    if (poolByType) {
      opts = [
        ...opts,
        ...poolByType.map((ls: LayoutSectionType) => {
          let result: SelectOptionType = {
            label: ls.name ? ls.name : "",
            value: ls.id ? ls.id : "",
          };
          if (ls.isDefault === true) {
            result.description = t("the_default_setting");
          }
          return result;
        }),
      ];
    }
    return opts;
  }, [poolByType, forRoutes, defaultID, type, t]);

  const handleChangeDef = (opt: any) => {
    handleChange(opt, type);
  };

  const customID = useMemo(() => {
    let result: string = LayoutSectionAuxOptions.USE_DEFAULT;
    if (initialID) {
      const found = initialID && poolByType.find((el) => el.id === initialID);
      if (found) result = initialID;
      if (
        initialID === LayoutSectionAuxOptions.DONT_USE ||
        initialID === LayoutSectionAuxOptions.USE_DEFAULT
      ) {
        result = initialID;
      }
    }
    return result;
  }, [poolByType, initialID]);

  let options = useMemo(() => {
    if (customID !== defaultID) {
      if (!rawOptions) return [];
      const result: SelectOptionType[] = rawOptions.map((el) =>
        el.value === customID &&
        el.value !== LayoutSectionAuxOptions.USE_DEFAULT
          ? { ...el, description: t("custom_explicit_for_this_route") }
          : el
      );
      return result;
    } else return rawOptions;
  }, [customID, defaultID, rawOptions, t]);

  return (
    <div className={classes.SelectLayoutSectionOuter}>
      {options && (
        <Select
          selectOptions={{
            initialValue: customID ? customID : defaultID,
            handleChange: handleChangeDef,
            options,
            optionTextAlign: SelectOptionTextAlign.LEFT,
          }}
        />
      )}
    </div>
  );
}

export default SelectLayoutSection;
