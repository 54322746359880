import { useMemo } from "react";
import { useAppContext } from "../../context/AppContext";
import {
  LayoutSectionType,
  LayoutSectionTypes,
  PageStructure,
  PageType,
} from "../../firebase/types";
import { findDefaultLayoutSectionPerType } from "../../helper-functions/general";
import Aux from "../../hoc/auxiliary";
import DyPaStructureElement from "../../routes/public/dynamic-pages/DyPaStructureElement";
import LayoutWrapper from "./LayoutWrapper";

interface LayoutComponentType {
  page?: PageType;
  type?: LayoutSectionTypes;
  use_default_layout_section?: boolean;
  use_explicit_layout_section?: LayoutSectionType;
  children?: any;
}

function LayoutComponent({
  page,
  type,
  use_default_layout_section,
  use_explicit_layout_section,
  children,
}: LayoutComponentType) {
  const { layoutSections, language } = useAppContext();

  const pageLayoutStructure = useMemo(() => {
    return page?.layoutStructure;
  }, [page]);

  const content = useMemo(() => {
    if (page && language) {
      const foundData = page.data.find((d: any) => d.language === language);
      if (foundData) {
        return foundData.content ? foundData.content : { key: "" };
      }
    }
  }, [page, language]);

  const layout = useMemo(() => {
    let result: LayoutSectionType | undefined;
    if (!type) return result;

    if (use_explicit_layout_section) {
      return use_explicit_layout_section;
    }

    if (pageLayoutStructure && !use_default_layout_section) {
      let id = "";
      switch (type) {
        case LayoutSectionTypes.HEADER:
          id = pageLayoutStructure.header ? pageLayoutStructure.header : "";
          break;
        case LayoutSectionTypes.MIDDLE:
          id = pageLayoutStructure.content_container
            ? pageLayoutStructure.content_container
            : "";
          break;
        case LayoutSectionTypes.CONTENT_LEFT:
          id = pageLayoutStructure.content_left
            ? pageLayoutStructure.content_left
            : "";
          break;
        case LayoutSectionTypes.CONTENT_RIGHT:
          id = pageLayoutStructure.content_right
            ? pageLayoutStructure.content_right
            : "";
          break;
        case LayoutSectionTypes.FOOTER:
          id = pageLayoutStructure.footer ? pageLayoutStructure.footer : "";
          break;

        default:
          break;
      }
      result = layoutSections.find((ls: LayoutSectionType) => ls.id === id);
    }

    if (!result) {
      result = findDefaultLayoutSectionPerType(layoutSections, type);
    }

    return result;
  }, [
    layoutSections,
    pageLayoutStructure,
    type,
    use_default_layout_section,
    use_explicit_layout_section,
  ]);

  if (children) {
    return (
      <LayoutWrapper type={type} layoutStyles={layout?.layoutStyles}>
        {children}
      </LayoutWrapper>
    );
  }

  return (
    <Aux>
      {/* Header & Footer: */}
      {layout && layout.structure ? (
        <Aux>
          <LayoutWrapper type={type} layoutStyles={layout.layoutStyles}>
            {layout.structure.map((pS: PageStructure, idx: number) => (
              <DyPaStructureElement item={pS} content={content} key={idx} />
            ))}
          </LayoutWrapper>
        </Aux>
      ) : null}

      {!use_default_layout_section && type === LayoutSectionTypes.ROUTES ? (
        <LayoutWrapper type={type}>
          {page?.structure?.map((pS: PageStructure, idx: number) => (
            <DyPaStructureElement item={pS} content={content} key={idx} />
          ))}
        </LayoutWrapper>
      ) : null}
    </Aux>
  );
}

export default LayoutComponent;
