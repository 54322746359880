import { useEffect, useState } from "react";
import { Cookies } from "../components/cookies/Cookies";
import { AppContextProvider } from "../context/AppContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Aux from "./auxiliary";

import Routing from "./Routing";
import {
  LocalStorageItems,
  LOCAL_STORAGE_COOKIES_ACCEPTED,
} from "../firebase/types";
import { getCachedItem, updateCachedItem } from "../context/local-storage";

function App() {
  const [cookies, setCookies] = useState<string | null>();
  useEffect(() => setCookies(getCachedItem(LocalStorageItems.COOKIES)), []);

  const acceptCookies = () => {
    updateCachedItem(LOCAL_STORAGE_COOKIES_ACCEPTED, LocalStorageItems.COOKIES);
    setCookies(LOCAL_STORAGE_COOKIES_ACCEPTED);
  };

  return (
    <Aux>
      <AppContextProvider>
        <DndProvider backend={HTML5Backend}>
          <Routing />
          {!cookies && <Cookies handleClick={acceptCookies} />}
        </DndProvider>
      </AppContextProvider>
    </Aux>
  );
}

export default App;
