import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import {
  createPage,
  updatePage,
  // getPages,
  // getSettings,
  saveSettings,
  deleteDocByPath,
  // getBasicComponents,
  createBasicComponent,
  updateBasicComponent,
  // getWrapperComponents,
  createWrapperComponent,
  updateWrapperComponent,
  // getLayoutSections,
  createLayoutSection,
  updateLayoutSection,
  updateLayoutSections,
  get_frontend_db,
} from "../firebase/firestore-database";
import {
  DbMediaType,
  LayoutSectionType,
  LayoutSectionTypes,
  PageType,
  SettingsType,
  BasicComponentType,
  WrapperComponentType,
  LocalStorageItems,
} from "../firebase/types";
import i18next from "i18next";
import {
  deleteMediaFile,
  getLogoUrl,
  uploadLogoImage,
  uploadMediaFiles,
} from "../firebase/storage";
import { getCachedItem, updateCachedItem } from "./local-storage";

type LanguageOptions = "de" | "en" | "ro" | "es";

interface AppContextType {
  currentUser: any;
  settings: SettingsType;
  language: string;
  change_language: (lang: LanguageOptions) => void;
  pages: PageType[];
  showSideDrawer: boolean;
  isMobileDevice: boolean;
  get_page_by_path: (path: string) => any;
  create_page: (request: any) => any;
  update_page: (request: any) => any;
  update_pages_order: (request: PageType[]) => any;
  delete_page: (id?: string) => any;
  save_settings: (request: SettingsType) => void;
  toggle_side_drawer: () => void;
  logoUrl: string;
  upload_logo_image: (file: FileList) => any;
  mediaFiles: DbMediaType[];
  upload_media_files: (files: FileList[]) => any;
  delete_media_file: (bucketPath: string, id: string) => any;
  // basic components
  basicComponents: BasicComponentType[];
  create_basic_component: (request: BasicComponentType) => any;
  update_basic_component: (request: BasicComponentType) => any;
  delete_basic_component: (id?: string) => any;
  // wrapperComponents
  wrapperComponents: WrapperComponentType[];
  create_wrapper_component: (request: WrapperComponentType) => any;
  update_wrapper_component: (request: WrapperComponentType) => any;
  delete_wrapper_component: (id?: string) => any;
  layoutSections: LayoutSectionType[];
  get_layout_section_by_id: (path: string) => any;
  create_layout_section: (request: LayoutSectionType) => any;
  update_layout_section: (request: LayoutSectionType) => any;
  delete_layout_section: (id?: string) => any;
  set_layout_sections_is_default_to_false_by_type: (
    type: LayoutSectionTypes
  ) => any;
}

const AppContext = React.createContext<AppContextType>(null!);

export function useAppContext() {
  return useContext(AppContext);
}

export function AppContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  let [currentUser, setCurrentUser] = useState<any>(null);
  const [settings, setSettings] = useState<any>();
  const [pages, setPages] = useState<PageType[]>([]);
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [language, setLanguage] = useState("en");
  const [logoUrl, setLogoUrl] = useState("");
  const [mediaFiles, setMediaFiles] = useState<DbMediaType[]>([]);
  const [basicComponents, setBasicComponents] = useState<BasicComponentType[]>(
    []
  );

  const [wrapperComponents, setWrappers] = useState<WrapperComponentType[]>([]);
  const [layoutSections, setLayoutSections] = useState<LayoutSectionType[]>([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        let signedIn = user && user.email ? user.email : "-";
        setCurrentUser(user);
        updateCachedItem(signedIn, LocalStorageItems.SIGNED_IN);
      },
      (error) => {
        alert("error onAuthStateChanged: " + error);
      },
      () => {}
    );

    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  useEffect(() => {
    const set_media = () => {
      let isMobileDevice = window.matchMedia("(max-width: 900px)").matches;
      setIsMobileDevice(isMobileDevice);
    };

    window.addEventListener("resize", set_media);
    set_media();

    return () => {
      window.removeEventListener("resize", set_media);
    };
  }, []);

  useEffect(() => {
    (async () => {
      let frontend = getCachedItem(LocalStorageItems.FRONTEND);

      if (!frontend) {
        frontend = await get_frontend_db();
        if (frontend) updateCachedItem(frontend, LocalStorageItems.FRONTEND);
      }

      if (frontend?.settings) setSettings(frontend.settings);
      if (frontend?.pages) setPages(frontend.pages);
      if (frontend?.logoUrl) setLogoUrl(frontend?.logoUrl);
      if (frontend?.basicComponents) {
        setBasicComponents(frontend?.basicComponents);
      }
      if (frontend?.wrappers) {
        setWrappers(frontend?.wrappers);
      }
      if (frontend?.layoutSections) {
        setLayoutSections(frontend?.layoutSections);
      }
    })();
  }, []);

  const change_language = async (lang: LanguageOptions) => {
    i18next.changeLanguage(lang);
    setLanguage(lang);
    updateCachedItem(lang, LocalStorageItems.LANGUAGE);
  };
  useEffect(() => {
    let lng = settings?.defLanguage ? settings.defLanguage : "en";
    const fromLS = getCachedItem(LocalStorageItems.LANGUAGE);
    if (
      fromLS === "de" ||
      fromLS === "en" ||
      fromLS === "ro" ||
      fromLS === "es"
    ) {
      lng = fromLS;
    }
    change_language(lng);
  }, [settings]);

  const toggle_side_drawer = () => {
    if (!isMobileDevice) return;
    setShowSideDrawer((prev) => !prev);
  };

  let get_page_by_path = (path: string) => {
    let found: PageType | undefined;
    if (pages) {
      found = pages.find((page: any) => page.path === path);
    }
    return found;
  };
  const updatePagesContextState = (page: PageType) => {
    if (!pages) {
      setPages([page]);
      return;
    }
    let parsedPages: PageType[] = [];
    let needToAdd = true;
    parsedPages = pages.map((pg: PageType) => {
      if (pg.id === page.id) {
        needToAdd = false;
        return page;
      } else return pg;
    });
    if (needToAdd) {
      parsedPages = [...pages, page];
    }

    setPages(parsedPages);
  };
  const updatePagesContextStateWithPages = (pgs: PageType[]) => {
    if (pgs && pgs.length) setPages(pgs);
  };
  const deletePageFromPagesById = (id: string) => {
    if (!pages) {
      // should attempt to delete from db
      return;
    }
    const parsedPages: PageType[] = pages.filter(
      (pg: PageType) => pg.id !== id
    );
    setPages(parsedPages);
  };
  let create_page = async (request: any) => {
    try {
      const newPage = { ...request, order: pages.length };
      const result: any = await createPage(newPage);
      if (result) {
        const docData: PageType = result;
        updatePagesContextState(docData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  let update_page = async (request: any) => {
    try {
      await updatePage(request);
      if (request) updatePagesContextState(request);
    } catch (error) {
      console.log(error);
    }
  };
  let update_pages_order = async (request: PageType[]) => {
    for (let index = 0; index < request.length; index++) {
      const p = { ...request[index], order: index };
      try {
        await updatePage(p);
      } catch (error) {
        console.log(error);
      }
    }
    updatePagesContextStateWithPages(
      request.map((pg: PageType, index: number) => ({ ...pg, order: index }))
    );
  };
  let delete_page = async (id?: string) => {
    if (!id) return;
    try {
      const result = await deleteDocByPath(`pages/${id}`);
      if (result === undefined) deletePageFromPagesById(id);
    } catch (error) {
      console.log(error);
    }
  };

  // basicComponents
  const updateBasicComponentsContextState = (req: BasicComponentType) => {
    if (!basicComponents || !basicComponents.length) {
      setBasicComponents([req]);
      return;
    }
    let parsed: BasicComponentType[] = [];
    let needToAdd = true;
    parsed = basicComponents.map((el: BasicComponentType) => {
      if (el.id === req.id) {
        needToAdd = false;
        return req;
      } else return el;
    });
    if (needToAdd) {
      parsed = [...basicComponents, req];
    }

    setBasicComponents(parsed);
  };
  const deleteBasicComponentFromBasicComponentsById = (id: string) => {
    if (!basicComponents) {
      // should attempt to delete from db
      return;
    }
    const parsed: BasicComponentType[] = basicComponents.filter(
      (el: BasicComponentType) => el.id !== id
    );
    setBasicComponents(parsed);
  };

  let create_basic_component = async (request: BasicComponentType) => {
    try {
      const result: any = await createBasicComponent(request);
      if (result.err) {
        console.log(result.err);
      } else {
        const docData: BasicComponentType = result;
        updateBasicComponentsContextState(docData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  let update_basic_component = async (request: BasicComponentType) => {
    try {
      await updateBasicComponent(request);
      if (request) updateBasicComponentsContextState(request);
    } catch (error) {
      console.log(error);
    }
  };
  let delete_basic_component = async (id?: string) => {
    if (!id) return;
    try {
      const result = await deleteDocByPath(`basic-components/${id}`);
      if (result === undefined) deleteBasicComponentFromBasicComponentsById(id);
    } catch (error) {
      console.log(error);
    }
  };
  // end basicComponents

  const save_settings = async (request: SettingsType) => {
    try {
      await saveSettings(request);
      setSettings(request);
    } catch (error) {
      console.log(error);
    }
  };
  const upload_logo_image = async (file: FileList) => {
    try {
      await uploadLogoImage(file);
      const data: any = await getLogoUrl();
      setLogoUrl(data);
    } catch (error) {
      console.log(error);
    }
  };
  const addToMediaFiles = (files: DbMediaType[]) => {
    if (mediaFiles && mediaFiles.length) {
      // no find, we do not update. all BY NAME
      setMediaFiles([...mediaFiles, ...files]);
    } else {
      setMediaFiles(files);
    }
  };
  const removeFromMediaFiles = (id: string) => {
    if (!mediaFiles || !mediaFiles.length) return;
    const newFiles = mediaFiles.filter((file: DbMediaType) => file.id !== id);
    setMediaFiles(newFiles);
  };
  const upload_media_files = async (files: FileList[]) => {
    try {
      const bucketDone = await uploadMediaFiles(files);
      if (bucketDone && bucketDone.length) addToMediaFiles(bucketDone);
    } catch (error) {
      console.log(error);
    }
  };
  const delete_media_file = async (bucketPath: string, id: string) => {
    try {
      const complete = await deleteMediaFile(bucketPath, id);
      if (complete === "success") removeFromMediaFiles(id);
    } catch (error) {
      console.log(error);
    }
  };

  // wrapperComponents:
  const updateWrapperComponentsContextState = (
    request: WrapperComponentType
  ) => {
    if (!wrapperComponents || !wrapperComponents.length) {
      setWrappers([request]);
      return;
    }
    let parsed: WrapperComponentType[] = [];
    let needToAdd = true;
    parsed = wrapperComponents.map((el: WrapperComponentType) => {
      if (el.id === request.id) {
        needToAdd = false;
        return request;
      } else return el;
    });
    if (needToAdd) {
      parsed = [...wrapperComponents, request];
    }

    setWrappers(parsed);
  };
  const deleteWrapperFromWrappersById = (id: string) => {
    if (!wrapperComponents) {
      // should attempt to delete from db
      return;
    }
    const parsed: WrapperComponentType[] = wrapperComponents.filter(
      (el: WrapperComponentType) => el.id !== id
    );
    setWrappers(parsed);
  };

  const reset_is_side_menu_for_wrapper_component = async (
    exceptionID: string
  ) => {
    const found = wrapperComponents.filter((el) => el.isSideMenu);
    if (found) {
      for (let index = 0; index < found.length; index++) {
        const element = found[index];
        if (element.id !== exceptionID) {
          await updateWrapperComponent({ ...element, isSideMenu: false });
          updateWrapperComponentsContextState(element);
        }
      }
    }
  };
  let create_wrapper_component = async (request: WrapperComponentType) => {
    try {
      const result: any = await createWrapperComponent(request);
      if (result.err) {
        console.log(result.err);
      } else {
        const docData: WrapperComponentType = result;
        updateWrapperComponentsContextState(docData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  let update_wrapper_component = async (request: WrapperComponentType) => {
    try {
      await updateWrapperComponent(request);
      if (request) updateWrapperComponentsContextState(request);
      if (request && request.isSideMenu === true) {
        const id = request?.id ? request.id : "";
        reset_is_side_menu_for_wrapper_component(id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  let delete_wrapper_component = async (id?: string) => {
    if (!id) return;
    try {
      const result = await deleteDocByPath(`wrapper-components/${id}`);
      if (result === undefined) deleteWrapperFromWrappersById(id);
    } catch (error) {
      console.log(error);
    }
  };
  // end wrapperComponents

  // layout-sections
  let delete_layout_section = async (id?: string) => {
    if (!id) return;
    try {
      const result = await deleteDocByPath(`layout-sections/${id}`);
      if (result === undefined) deleteLayoutSectionFromLSsById(id);
    } catch (error) {
      console.log(error);
    }
  };
  let get_layout_section_by_id = (id: string) => {
    let found: LayoutSectionType | undefined;
    if (layoutSections) {
      found = layoutSections.find((item: LayoutSectionType) => item.id === id);
    }
    return found;
  };
  let create_layout_section = async (request: LayoutSectionType) => {
    try {
      await set_layout_sections_is_default_to_false_by_type(
        request.type as LayoutSectionTypes
      );
      const result = await createLayoutSection(request);
      if (result) {
        updateLayoutSectionsContextState(result as LayoutSectionType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let set_layout_sections_is_default_to_false_by_type = async (
    type: LayoutSectionTypes
  ) => {
    try {
      const poolByTypeSetToFalse: LayoutSectionType[] = layoutSections
        .filter((el) => el.type === type)
        .map((el) => ({ ...el, isDefault: false }));
      await updateLayoutSections(poolByTypeSetToFalse);

      const toUpdate = layoutSections.map((el) =>
        el.type === type ? { ...el, isDefault: false } : el
      );
      setLayoutSections(toUpdate);
    } catch (error) {
      console.log(error);
    }
  };
  let set_layout_sections_is_default_to_false_by_type_and_id = async (
    request: LayoutSectionType
  ) => {
    if (request.isDefault === true) {
      const poolByTypeSetToFalse: LayoutSectionType[] = layoutSections
        .filter((el) => el.type === request.type && el.id !== request.id)
        .map((el) => ({ ...el, isDefault: false }));
      await updateLayoutSections(poolByTypeSetToFalse);
    }
  };
  let update_layout_section = async (request: LayoutSectionType) => {
    try {
      await set_layout_sections_is_default_to_false_by_type_and_id(request);
      await updateLayoutSection(request);
      if (request && !request.hasOwnProperty("err")) {
        updateLayoutSectionsContextState(request);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLayoutSectionFromLSsById = (id: string) => {
    if (!layoutSections) {
      // should attempt to delete from db
      return;
    }
    const parsed: LayoutSectionType[] = layoutSections.filter(
      (item: LayoutSectionType) => item.id !== id
    );
    setLayoutSections(parsed);
  };
  const updateLayoutSectionsContextState = (req: LayoutSectionType) => {
    if (!layoutSections) {
      setLayoutSections([req]);
      return;
    }
    const updateIsDefault = req.isDefault === true;
    let parsed: LayoutSectionType[] = [];
    let needToAdd = true;
    parsed = layoutSections.map((item: LayoutSectionType) => {
      if (item.type === req.type) {
        if (item.id === req.id) {
          needToAdd = false;
          return req;
        } else {
          let newItem = { ...item };
          if (updateIsDefault) newItem.isDefault = false;
          return newItem;
        }
      } else return item;
    });
    if (needToAdd) {
      parsed = [...parsed, req];
    }
    setLayoutSections(parsed);
  };

  let value = {
    currentUser,
    settings,
    language,
    change_language,
    pages,
    isMobileDevice,
    showSideDrawer,
    get_page_by_path,
    create_page,
    update_page,
    update_pages_order,
    delete_page,
    save_settings,
    toggle_side_drawer,
    logoUrl,
    upload_logo_image,
    mediaFiles,
    upload_media_files,
    delete_media_file,

    basicComponents,
    create_basic_component,
    update_basic_component,
    delete_basic_component,

    wrapperComponents,
    create_wrapper_component,
    update_wrapper_component,
    delete_wrapper_component,
    layoutSections,
    get_layout_section_by_id,
    create_layout_section,
    update_layout_section,
    delete_layout_section,
    set_layout_sections_is_default_to_false_by_type,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
