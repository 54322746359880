import classes from "./basic-component.module.css";
import { useAppContext } from "../../context/AppContext";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  parseJsonStringifiedToJsonObject,
  parseStyleCard,
} from "../../helper-functions/general";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  BasicComponentDataType,
  BasicComponentType,
  ActionActionOpenSideMenu,
} from "../../firebase/types";
import StyledDivOrAux from "../../hoc/StyledDivOrAux";
import DynamicPage from "../../routes/public/dynamic-pages/Dynamic-pages";

function CardsComponent({
  card,
  toggleSideDrawer,
}: {
  card: BasicComponentType;
  toggleSideDrawer?: boolean;
}): ReactElement {
  const navigate = useNavigate();
  const { language, isMobileDevice, toggle_side_drawer } = useAppContext();
  const [isHovering, setIsHovering] = useState(-1);

  const [showIFrame, setShowIFrame] = useState("");
  const [outletPath, setOutletPath] = useState("");

  useEffect(() => {
    setShowIFrame("");
    setOutletPath("");
  }, [card]);

  const cardHoverStyle = useMemo(() => {
    return parseJsonStringifiedToJsonObject(
      isMobileDevice ? "" : card.styleListElementHover
    );
  }, [isMobileDevice, card]);
  const cardStyle = useMemo(() => {
    return parseJsonStringifiedToJsonObject(
      isMobileDevice ? card.styleListElementM : card.styleListElement
    );
  }, [isMobileDevice, card]);
  const shouldHover = useMemo(
    () => Object.keys(cardHoverStyle).length > 0,
    [cardHoverStyle]
  );
  const toggleIsHovering = useCallback(
    (val: number) => shouldHover && setIsHovering(val),
    [shouldHover]
  );

  const handleClick = (
    ev: React.BaseSyntheticEvent,
    path?: string,
    openIn?: number
  ) => {
    ev.stopPropagation();
    if (!path) return;

    if (path === ActionActionOpenSideMenu) {
      toggle_side_drawer();
      return;
    }

    switch (openIn) {
      case 1:
        window.open(path, "_blank");
        break;
      case 2:
        if (path.indexOf("http") === 0) {
          setShowIFrame(path);
          setOutletPath("");
        } else {
          setShowIFrame("");
          setOutletPath(path);
        }
        break;

      default:
        if (path.indexOf("https://") === 0) {
          window.open(path, "_self");
        } else {
          navigate(path.indexOf("/") === 0 ? path : `/page/${path}`);
        }
        break;
    }

    if (toggleSideDrawer) toggle_side_drawer();
  };

  if (!card) return <div></div>;

  return (
    <StyledDivOrAux
      styleObj={parseJsonStringifiedToJsonObject(
        isMobileDevice ? card.styleOuterM : card.styleOuter
      )}
    >
      <StyledDivOrAux
        styleObj={parseJsonStringifiedToJsonObject(
          isMobileDevice ? card.styleListM : card.styleList
        )}
      >
        {card.data &&
          card.data.map((el: BasicComponentDataType, idx: number) => (
            <motion.div
              initial={{ opacity: 0.25 }}
              whileInView={{ opacity: 1 }}
              key={idx}
              style={parseStyleCard(
                el,
                isHovering === idx ? cardHoverStyle : cardStyle
              )}
              onClick={(ev: React.BaseSyntheticEvent) =>
                handleClick(ev, el.itemClick, el.openIn)
              }
              onMouseEnter={() => toggleIsHovering(idx)}
              onMouseLeave={() => toggleIsHovering(-1)}
            >
              {el.title &&
              el.title.hasOwnProperty(language) &&
              el.title[language] !== "" ? (
                <div
                  className={classes.Title}
                  style={parseJsonStringifiedToJsonObject(
                    isMobileDevice ? card.styleTitleM : card.styleTitle
                  )}
                >
                  {el.title[language]}
                </div>
              ) : null}

              {el.text &&
              el.text.hasOwnProperty(language) &&
              el.text[language] !== "" ? (
                <div
                  className={classes.Text}
                  style={parseJsonStringifiedToJsonObject(
                    isMobileDevice ? card.styleTextM : card.styleText
                  )}
                  dangerouslySetInnerHTML={{
                    __html: el.text[language],
                  }}
                ></div>
              ) : null}
            </motion.div>
          ))}
      </StyledDivOrAux>

      {outletPath && (
        <div
          style={parseJsonStringifiedToJsonObject(
            isMobileDevice ? card.styleOutletM : card.styleOutlet
          )}
        >
          <DynamicPage outletPath={outletPath} />
        </div>
      )}

      {showIFrame && (
        <iframe
          title="ifr"
          name="ifr"
          className={showIFrame ? classes.Outlet : classes.OutletHidden}
          style={parseJsonStringifiedToJsonObject(
            isMobileDevice ? card.styleOutletM : card.styleOutlet
          )}
          src={showIFrame}
          width="900"
          height="600"
          frameBorder="0"
        >
          &nbsp;
        </iframe>
      )}
    </StyledDivOrAux>
  );
}

export default CardsComponent;
