import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import {
  AdminPaths,
  BasicComponentTypes,
  LayoutSectionTypes,
} from "../firebase/types";

import LayoutClient from "./layout/LayoutClient";
import DynamicPage from "../routes/public/dynamic-pages/Dynamic-pages";
import ErrorBoundary from "./ErrorBoundary";

const LayoutAdmin = React.lazy(() => import("./layout/LayoutAdmin"));
const RequireAuth = React.lazy(
  () => import("../components/navigation/access-public/RequireAuth")
);
const AdminLanding = React.lazy(() => import("../routes/admin/Landing"));
const Authenticate = React.lazy(
  () => import("../routes/public/authenticate/Authenticate")
);

const PageNotFound = React.lazy(
  () => import("../routes/page-not-found/PageNotFound")
);

const Themes = React.lazy(() => import("../routes/admin/Themes"));
const Layout = React.lazy(() => import("../routes/admin/layout/Layout"));
const LayoutSectionByType = React.lazy(
  () => import("../routes/admin/layout/LayoutSectionByType")
);
const EditLayoutSection = React.lazy(
  () => import("../routes/admin/layout/EditLayoutSection")
);
const Pages = React.lazy(() => import("../routes/admin/Pages"));
const PageById = React.lazy(() => import("../routes/admin/PageById"));
const Components = React.lazy(
  () => import("../routes/admin/basic-components/Components")
);
const BasicComponents = React.lazy(
  () => import("../routes/admin/basic-components/BasicComponents")
);
const HocComponents = React.lazy(
  () => import("../routes/admin/high-order-components/HocMainView")
);
const HighOrderComponents = React.lazy(
  () => import("../routes/admin/high-order-components/HighOrderComponents")
);
const HocTemplates = React.lazy(
  () => import("../routes/admin/high-order-components/HocTemplates")
);
const TemplateHocById = React.lazy(
  () => import("../routes/admin/high-order-components/TemplateHocById")
);
const Templates = React.lazy(
  () => import("../routes/admin/basic-components/Templates")
);
const TemplateComponentById = React.lazy(
  () => import("../routes/admin/basic-components/TemplateComponentById")
);
const Media = React.lazy(() => import("../routes/admin/media/Media"));
const Settings = React.lazy(() => import("../routes/admin/Settings"));

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutClient />}>
        <Route index element={<DynamicPage homePage={true} />} />
        <Route
          path="/authenticate"
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <Authenticate />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route path="/page/:id" element={<DynamicPage />} />

        <Route
          path="*"
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <PageNotFound />
              </Suspense>
            </ErrorBoundary>
          }
        />
      </Route>

      <Route
        path={AdminPaths.BASE}
        element={
          <ErrorBoundary>
            <Suspense fallback={<div></div>}>
              <RequireAuth>
                <LayoutAdmin />
              </RequireAuth>
            </Suspense>
          </ErrorBoundary>
        }
      >
        <Route
          path={AdminPaths.USER}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <AdminLanding />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={AdminPaths.THEMES}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <Themes />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={AdminPaths.LAYOUT}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <Layout />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/:type`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <LayoutSectionByType />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/${LayoutSectionTypes.HEADER}/:id`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <EditLayoutSection />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/${LayoutSectionTypes.MIDDLE}/:id`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <EditLayoutSection />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/${LayoutSectionTypes.CONTENT_LEFT}/:id`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <EditLayoutSection />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/${LayoutSectionTypes.CONTENT_RIGHT}/:id`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <EditLayoutSection />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.LAYOUT}/${LayoutSectionTypes.FOOTER}/:id`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <EditLayoutSection />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={AdminPaths.ROUTES}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <Pages />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.ROUTES}/:p`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <PageById />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={AdminPaths.BASIC_COMPONENTS}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <Components />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/slide-shows`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <BasicComponents type={BasicComponentTypes.SLIDESHOW} />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/cards`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <BasicComponents type={BasicComponentTypes.CARD} />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/faqs`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <BasicComponents type={BasicComponentTypes.FAQ} />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/dropdowns`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <BasicComponents type={BasicComponentTypes.DROPDOWN} />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/templates`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <Templates />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.BASIC_COMPONENTS}/templates/:p`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <TemplateComponentById />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={AdminPaths.HOCS}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <HocComponents />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.HOCS}/items`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <HighOrderComponents />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.HOCS}/templates`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <HocTemplates />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${AdminPaths.HOCS}/templates/:p`}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <TemplateHocById />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={AdminPaths.MEDIA}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <Media />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path={AdminPaths.SETTINGS}
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <Settings />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="*"
          element={
            <ErrorBoundary>
              <Suspense fallback={<div></div>}>
                <PageNotFound />
              </Suspense>
            </ErrorBoundary>
          }
        />
      </Route>
    </Routes>
  );
};

export default Routing;
